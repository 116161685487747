import redirectAfterSignInOrSignUp from "components/Authenticator/utils/redirectAfterSignInOrSignUp";
import {
  FacebookAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
} from "firebase/auth";

const facebookProvider = new FacebookAuthProvider();

async function signInWithFacebook(
  firebaseAuth,
  setPendingCredentials,
  setEmail,
  setViewMode,
  setOldSingInMethod,
  setUser,
  setUserProfile,
  router,
  setShowLogInDialog
) {
  let signInWithPopupResp;
  try {
    signInWithPopupResp = await signInWithPopup(firebaseAuth, facebookProvider);
  } catch (error) {
    console.log(error);

    // An error happened.
    if (error.code === "auth/account-exists-with-different-credential") {
      const pendingCred = FacebookAuthProvider.credentialFromError(error);
      setPendingCredentials(pendingCred);
      // The provider account's email address.
      const email = error.customData.email;
      setEmail(email);
      // Get sign-in methods for this email.
      let methods;
      try {
        methods = await fetchSignInMethodsForEmail(firebaseAuth, email);
      } catch (error) {
        console.log(error);
      }

      if (methods[0] === "password") {
        setViewMode("promptForPasswordForLinking");
        return;
      } else {
        setOldSingInMethod(methods[0]);
        setViewMode("promptForProviderForLinking");
        return;
      }
    }

    // exit after error
    return;
  }

  // The signed-in user info.
  const user = signInWithPopupResp.user;
  const { isNewUser } = getAdditionalUserInfo(signInWithPopupResp);

  await redirectAfterSignInOrSignUp(
    user,
    setUser,
    setUserProfile,
    setViewMode,
    router,
    isNewUser,
    false,
    setShowLogInDialog
  );
}

export default signInWithFacebook;
