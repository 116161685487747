import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
// store utils
import { useStore } from "outstated";
import { AppStore } from "pages/_app";
// lib
import handleContinue from "components/Authenticator/utils/handleContinue";
import handleLogin from "components/Authenticator/utils/handleLogin";
import handleSignUp from "components/Authenticator/utils/handleSignUp";
import handlePasswordReset from "components/Authenticator/utils/handlePasswordReset";
import validatePasswordLength from "components/Authenticator/utils/validatePasswordLength";
import handleAccountLinkingWithPassword from "components/Authenticator/utils/handleAccountLinkingWithPassword";
import handleAccountLinkingWithProvider from "components/Authenticator/utils/handleAccountLinkingWithProvider";
import signInWithGoogle from "components/Authenticator/utils/signInWithGoogle";
import signInWithFacebook from "components/Authenticator/utils/signInWithFacebook";
// mui
import { useTheme } from "@mui/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FacebookIcon from "@mui/icons-material/Facebook";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { visuallyHidden } from "@mui/utils";
//
import restrictedList from "components/Authenticator/utils/restrictedList";
// firebase auth

export default function Authenticator({
  showTitle = true,
  customTitle = false,
}) {
  const theme = useTheme();
  const router = useRouter();

  const {
    setUser,
    setUserProfile,
    setIsNewUser,
    firebaseAuth,
    setShowLogInDialog,
  } = useStore(AppStore);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signUpMode, setSignUpMode] = useState(false);
  const [viewMode, setViewMode] = useState("continue");
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const [verifyCodeErrorMessage, setVerifyCodeErrorMessage] = useState(false);
  const [pendingCredentials, setPendingCredentials] = useState({});
  const [oldSingInMethod, setOldSingInMethod] = useState("");
  const [cypherString, setCypherString] = useState(null);

  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cypherString = urlParams.get("c");
    if (cypherString) {
      setCypherString(cypherString);
    }
  }, []);

  useEffect(() => {
    setEmailError(false);
    setEmailErrorMessage("");
    setLoginErrorMessage("");
    setPasswordErrorMessage("");
  }, [email, password]);

  useEffect(() => {
    setPassword("");
  }, [signUpMode]);

  useEffect(() => {
    if (passwordErrorMessage !== "") {
      document.getElementById("password-input-field").focus();
    }
  }, [passwordErrorMessage]);

  useEffect(() => {
    if (viewMode === "successfulSignUp") {
      document.getElementById("your-account-has-been-created").focus();
    }

    if (viewMode === "signUp") {
      document.getElementById("password-input-field").focus();
    }

    if (viewMode === "login") {
      document.getElementById("password-input-field").focus();
    }
  }, [viewMode]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container
      sx={{
        width: "100%",
        // margin: theme.spacing(3, "auto"),
        padding: 0,
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(3),
        }}
      >
        <Typography as={"h1"} sx={visuallyHidden}>
          Login or sign up for OfferMarket
        </Typography>

        {showTitle && (
          <Box
            sx={{
              color: theme.palette.primary.main,
              fontFamily: "Montserrat",
              fontSize: theme.spacing(3.5),
              textAlign: "center",
            }}
          >
            {customTitle
              ? customTitle
              : cypherString && cypherString.length > 0
              ? "Enter the invite email"
              : "Sign up or Log in"}
          </Box>
        )}
        {viewMode === "continue" && ( // this initial default step
          <>
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  // inputRef={(input) => input && input.focus()} // TODO make auto focus work on pages where necessary only
                  error={emailError}
                  label={emailError ? "Error" : "Enter email"}
                  helperText={emailError && emailErrorMessage}
                  sx={{ margin: theme.spacing(1, 0, 0, 0) }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />

                {loginErrorMessage !== "" && (
                  <Box sx={{ color: theme.palette.error.main }}>
                    {loginErrorMessage}
                  </Box>
                )}
                {!restrictedList.includes(email) && (
                  <Button
                    id={"continue-button"}
                    sx={{
                      height: theme.spacing(7),
                      margin: theme.spacing(1, 0, 0, 0),
                    }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      handleContinue(
                        firebaseAuth,
                        email,
                        setEmailError,
                        setEmailErrorMessage,
                        setViewMode,
                        cypherString
                      );
                    }}
                  >
                    Continue
                  </Button>
                )}
              </Box>
            </>

            <Divider sx={{ margin: theme.spacing(2, 0, 2, 0) }} />
            {!(cypherString && cypherString.length > 0) && (
              <Box>
                {!restrictedList.includes(email) ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      sx={{
                        height: theme.spacing(6),
                        color: "black",
                        backgroundColor: "white",
                        [theme.breakpoints.down("md")]: {
                          height: theme.spacing(6),
                          lineHeight: theme.spacing(2),
                        },
                      }}
                      variant="contained"
                      color="white"
                      onClick={() => {
                        signInWithGoogle(
                          firebaseAuth,
                          setPendingCredentials,
                          setEmail,
                          setViewMode,
                          setOldSingInMethod,
                          setUser,
                          setUserProfile,
                          setShowLogInDialog,
                          router
                        );
                      }}
                    >
                      <span
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1pllugm-MuiSvgIcon-root"
                        style={{
                          marginRight: "auto",
                          background:
                            "url('/Google__G__Logo.svg') transparent 50% no-repeat",
                          width: "26px",
                          height: "26px",
                        }}
                      />
                      <Box sx={{ width: "90%", textAlign: "center" }}>
                        {`${signUpMode ? "Sign up" : "Sign in"} with Google`}
                      </Box>
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    Unfortunately this email address has been restricted because
                    one or more of previous emails from OfferMarket{" "}
                    <b>were marked as spam</b>.
                    <br />
                    <br />
                    Marking our emails as spam hurts our ability to serve our
                    clients.
                    <br />
                    <br />
                    If you would like to be reinstated, please email{" "}
                    <b>hello@offermarket.us</b>. Have a great day!
                    <br />
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
        {viewMode === "login" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {deliveryMethod !== "" && (
              <Box
                sx={{
                  fontSize: theme.spacing(3),
                  color: theme.palette.success.main,
                }}
              >
                The code was sent to your{" "}
                {deliveryMethod === "email"
                  ? "email. Check spam folder."
                  : deliveryMethod}
              </Box>
            )}
            <FormControl
              sx={{ margin: theme.spacing(1, 0, 0, 0) }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="password-input-field"
                sx={{
                  color:
                    passwordErrorMessage !== ""
                      ? theme.palette.error.main
                      : "inherit",
                }}
              >
                {passwordErrorMessage !== ""
                  ? passwordErrorMessage
                  : "Password"}
              </InputLabel>
              <OutlinedInput
                error={passwordErrorMessage !== ""}
                id="password-input-field"
                type={values.showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={
                  passwordErrorMessage !== ""
                    ? passwordErrorMessage
                    : "Password"
                }
              />
            </FormControl>
            <Button
              sx={{
                height: theme.spacing(7),
                margin: theme.spacing(1, 0, 0, 0),
              }}
              variant="contained"
              color="success"
              onClick={() => {
                handleLogin(
                  firebaseAuth,
                  email,
                  password,
                  setPasswordErrorMessage,
                  router,
                  setUser,
                  setUserProfile,
                  setViewMode,
                  setShowLogInDialog
                );
              }}
            >
              Log in
            </Button>
            <Button
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                [theme.breakpoints.down("md")]: {
                  height: theme.spacing(6),
                  lineHeight: theme.spacing(2),
                },
              }}
              onClick={() => {
                handlePasswordReset(
                  firebaseAuth,
                  email,
                  setEmailError,
                  setEmailErrorMessage,
                  setDeliveryMethod
                );
              }}
              variant="contained"
              color="info"
            >
              Reset password
            </Button>
            <Button
              onClick={() => {
                setViewMode("continue");
              }}
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                width: "30%",
              }}
              variant="contained"
              color="warning"
            >
              Back
            </Button>
          </Box>
        )}
        {viewMode === "promptForPasswordForLinking" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontSize: theme.spacing(3),
                color: theme.palette.success.main,
              }}
            >
              {`You already have an account using a different sign in method (password). Login to enable both sign in methods.`}
            </Box>
            <FormControl
              sx={{ margin: theme.spacing(1, 0, 0, 0) }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="password-input-field"
                sx={{
                  color:
                    passwordErrorMessage !== ""
                      ? theme.palette.error.main
                      : "inherit",
                }}
              >
                {passwordErrorMessage !== ""
                  ? passwordErrorMessage
                  : "Password"}
              </InputLabel>
              <OutlinedInput
                error={passwordErrorMessage !== ""}
                id="password-input-field"
                type={values.showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={
                  passwordErrorMessage !== ""
                    ? passwordErrorMessage
                    : "Password"
                }
              />
            </FormControl>
            <Button
              sx={{
                height: theme.spacing(7),
                margin: theme.spacing(1, 0, 0, 0),
              }}
              variant="contained"
              color="success"
              onClick={() => {
                handleAccountLinkingWithPassword(
                  firebaseAuth,
                  email,
                  password,
                  setPasswordErrorMessage,
                  router,
                  setUser,
                  setUserProfile,
                  setViewMode,
                  pendingCredentials
                );
              }}
            >
              Log in
            </Button>
            <Button
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                [theme.breakpoints.down("md")]: {
                  height: theme.spacing(6),
                  lineHeight: theme.spacing(2),
                },
              }}
              onClick={() => {
                handlePasswordReset(
                  firebaseAuth,
                  email,
                  setEmailError,
                  setEmailErrorMessage,
                  setDeliveryMethod
                );
              }}
              variant="contained"
              color="info"
            >
              Reset password
            </Button>
            <Button
              onClick={() => {
                setViewMode("continue");
              }}
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                width: "30%",
              }}
              variant="contained"
              color="warning"
            >
              Back
            </Button>
          </Box>
        )}
        {viewMode === "promptForProviderForLinking" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <><><> */}
            <Box
              sx={{
                fontSize: theme.spacing(3),
                color: theme.palette.success.main,
              }}
            >
              {`You already have an account using a different sign in method (${oldSingInMethod}). Login to enable both sign in methods.`}
            </Box>

            <Button
              sx={{
                height: theme.spacing(7),
                margin: theme.spacing(1, 0, 0, 0),
              }}
              variant="contained"
              color="success"
              onClick={() => {
                handleAccountLinkingWithProvider(
                  firebaseAuth,
                  router,
                  setUser,
                  pendingCredentials,
                  oldSingInMethod,
                  setUserProfile,
                  setViewMode
                );
              }}
            >
              Continue
            </Button>
            <Button
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                [theme.breakpoints.down("md")]: {
                  height: theme.spacing(6),
                  lineHeight: theme.spacing(2),
                },
              }}
              onClick={() => {
                handlePasswordReset(
                  firebaseAuth,
                  email,
                  setEmailError,
                  setEmailErrorMessage,
                  setDeliveryMethod
                );
              }}
              variant="contained"
              color="info"
            >
              Reset password
            </Button>
            <Button
              onClick={() => {
                setViewMode("continue");
              }}
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                width: "30%",
              }}
              variant="contained"
              color="warning"
            >
              Back
            </Button>
          </Box>
        )}
        {viewMode === "signUp" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl
              sx={{ margin: theme.spacing(1, 0, 0, 0) }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="password-input-field"
                sx={{
                  color:
                    passwordErrorMessage !== ""
                      ? theme.palette.error.main
                      : "inherit",
                }}
              >
                {passwordErrorMessage !== ""
                  ? passwordErrorMessage
                  : "Password"}
              </InputLabel>
              <OutlinedInput
                ariaLabel={"Please enter your desired password"}
                error={passwordErrorMessage !== ""}
                id="password-input-field"
                label={
                  passwordErrorMessage !== ""
                    ? passwordErrorMessage
                    : "Password"
                }
                type={values.showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              sx={{ margin: theme.spacing(1, 0, 0, 0) }}
              variant="outlined"
            >
              <InputLabel
                sx={{
                  color:
                    passwordErrorMessage !== ""
                      ? theme.palette.error.main
                      : "inherit",
                }}
                htmlFor="outlined-adornment-c-password"
              >
                Confirm password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-c-password"
                label="Confirm password"
                type={values.showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box>
              {password !== "" && (
                <Box sx={{ margin: theme.spacing(2, 0) }}>
                  <Box>Password must include:</Box>
                  <br />
                  <ul>
                    <li>
                      <Box
                        sx={{
                          textDecoration: validatePasswordLength(password)
                            ? `line-through ${theme.palette.success.main}`
                            : "none",
                        }}
                      >
                        {"At least 6 characters long"}
                      </Box>
                    </li>
                    <li>
                      <Box
                        sx={{
                          textDecoration:
                            password === confirmPassword
                              ? `line-through ${theme.palette.success.main}`
                              : "none",
                        }}
                      >
                        {"Password must match confirmation"}
                      </Box>
                    </li>
                  </ul>
                </Box>
              )}
              {loginErrorMessage !== "" && (
                <Box sx={{ color: theme.palette.error.main }}>
                  {loginErrorMessage}
                </Box>
              )}
            </Box>
            <Button
              sx={{
                height: theme.spacing(7),
                margin: theme.spacing(1, 0, 0, 0),
              }}
              variant="contained"
              color="success"
              onClick={() => {
                handleSignUp(
                  firebaseAuth,
                  email,
                  password,
                  confirmPassword,
                  setPasswordErrorMessage,
                  router,
                  setUser,
                  setViewMode,
                  setUserProfile,
                  setIsNewUser,
                  cypherString
                );
              }}
            >
              Sign up
            </Button>

            <Button
              onClick={() => {
                setViewMode("continue");
              }}
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                width: "30%",
              }}
              variant="contained"
              color="warning"
            >
              Back
            </Button>
          </Box>
        )}
        {viewMode === "successfulSignUp" && (
          <Box
            tabindex={0}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontSize: theme.spacing(3),
                color: theme.palette.success.main,
              }}
            >
              <div id={"your-account-has-been-created"} tabindex={0}>
                Your account has been created!
              </div>
            </Box>
            <Box
              tabindex={0}
              sx={{
                margin: theme.spacing(1, 0, 1, 0),
                fontSize: theme.spacing(3),
              }}
            >
              <div tabindex={0}>
                Here is what you can do now that you have an account:
              </div>
            </Box>

            <Button
              sx={{
                margin: theme.spacing(1, 0, 0, 0),
              }}
              onClick={() => {
                router.push("/survey/subscribe/deal-flow?noIntro=true");
              }}
              variant="contained"
              color="primary"
            >
              Be the first to know about new properties
            </Button>
            <Button
              sx={{
                margin: theme.spacing(1, 0, 0, 0),
              }}
              onClick={() => {
                router.push("/listings");
              }}
              variant="contained"
              color="primary"
            >
              View all listed properties
            </Button>
            <Button
              sx={{
                margin: theme.spacing(1, 0, 0, 0),
              }}
              onClick={() => {
                router.push("/sell");
              }}
              variant="contained"
              color="primary"
            >
              List a property
            </Button>
            <Button
              sx={{
                margin: theme.spacing(1, 0, 0, 0),
              }}
              onClick={() => {
                router.push("/loans");
              }}
              variant="contained"
              color="primary"
            >
              Get a loan
            </Button>
          </Box>
        )}
        {viewMode === "privelgedWrongEmailError" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: theme.spacing(1, 0),
            }}
          >
            <Box sx={{ color: theme.palette.error.main }}>
              Entered email doesn't match the invite
            </Box>

            <Button
              onClick={() => {
                setViewMode("continue");
              }}
              sx={{
                height: theme.spacing(4),
                margin: theme.spacing(1, 0, 0, "auto"),
                width: "30%",
              }}
              variant="contained"
              color="warning"
            >
              Back
            </Button>
          </Box>
        )}
      </Paper>
      <Box sx={{ fontSize: theme.spacing(0.5) }}>
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: theme.spacing(1.25), mt: 2 }}
        >
          By signing up and logging in, you agree to our{" "}
          <a href="https://www.offermarket.us/terms-of-service">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="https://www.offermarket.us/privacy-policy">Privacy Policy</a>
          , and consent to receive communications (SMS, email) necessary to
          deliver our services.
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: theme.spacing(1.25), mt: 2 }}
        >
          If you have any concerns please email us at{" "}
          <a href="mailto:hello@offermarket.us">hello@offermarket.us</a>
        </Typography>
      </Box>
    </Container>
  );
}
