import signIn from "components/Authenticator/utils/signIn";
import signInWithCustom from "components/Authenticator/utils/singInWithCustom";
import redirectAfterSignInOrSignUp from "components/Authenticator/utils/redirectAfterSignInOrSignUp";

async function handleLogin(
  firebaseAuth,
  email,
  password,
  setPasswordErrorMessage,
  router,
  setUser,
  setUserProfile,
  setViewMode,
  setShowLogInDialog
) {
  if (password.length === 0) {
    setPasswordErrorMessage("Enter password");
    return;
  }

  try {
    const signInResp = await signIn(firebaseAuth, email, password);

    const user = signInResp.user;

    await redirectAfterSignInOrSignUp(
      user,
      setUser,
      setUserProfile,
      setViewMode,
      router,
      false,
      false,
      setShowLogInDialog
    );

    return;
  } catch (error) {
    if (error.code === "auth/wrong-password") {
      setPasswordErrorMessage("Wrong password");
      return;
    }
  }
}

export default handleLogin;
