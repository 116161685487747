import redirectAfterSignInOrSignUp from "components/Authenticator/utils/redirectAfterSignInOrSignUp";
import {
  GoogleAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
} from "firebase/auth";

const googleProvider = new GoogleAuthProvider();

async function signInWithGoogle(
  firebaseAuth,
  setPendingCredentials,
  setEmail,
  setViewMode,
  setOldSingInMethod,
  setUser,
  setUserProfile,
  setShowLogInDialog,
  router
) {
  let signInWithPopupResp;

  try {
    signInWithPopupResp = await signInWithPopup(firebaseAuth, googleProvider);
  } catch (error) {
    // An error happened.
    if (error.code === "auth/account-exists-with-different-credential") {
      const pendingCred = GoogleAuthProvider.credentialFromError(error);
      setPendingCredentials(pendingCred);
      // The provider account's email address.
      const email = error.customData.email;
      setEmail(email);
      // Get sign-in methods for this email.
      let methods;
      try {
        methods = await fetchSignInMethodsForEmail(firebaseAuth, email);
      } catch (error) {
        console.log(error);
      }

      if (methods[0] === "password") {
        setViewMode("promptForPasswordForLinking");
        return;
      } else {
        setOldSingInMethod(methods[0]);
        setViewMode("promptForProviderForLinking");
        return;
      }
    }
    // exit after error
    return;
  }

  const user = signInWithPopupResp.user;

  const { isNewUser } = getAdditionalUserInfo(signInWithPopupResp);

  await redirectAfterSignInOrSignUp(
    user,
    setUser,
    setUserProfile,
    setViewMode,
    router,
    isNewUser,
    false,
    setShowLogInDialog
  );
}
export default signInWithGoogle;
