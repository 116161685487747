import signUp from "components/Authenticator/utils/signUp";
import validatePasswordLength from "components/Authenticator/utils/validatePasswordLength";
import redirectAfterSignInOrSignUp from "components/Authenticator/utils/redirectAfterSignInOrSignUp";
import validatePermissionAssignment from "components/Authenticator/utils/validatePermissionAssignment";

async function handleSignUp(
  firebaseAuth,
  email,
  password,
  confirmPassword,
  setPasswordErrorMessage,
  router,
  setUser,
  setViewMode,
  setUserProfile,
  setIsNewUser,
  cypherString,
  setShowLogInDialog
) {
  // since sign up already happened,
  // in this

  let validPassword = false;
  // make sure password matches all conditions
  if (password === confirmPassword && validatePasswordLength(password)) {
    validPassword = true;
  } else {
    if (!validatePasswordLength(password)) {
      setPasswordErrorMessage("Password must be at least 6 characters long");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordErrorMessage("Password must match confirmation password");
      return;
    }
  }

  let signUpResp;
  try {
    signUpResp = await signUp(firebaseAuth, email, password);
    const user = signUpResp.user;
    const isNewUser = true;
    setIsNewUser(isNewUser);

    // pass cypher string for decoding to the backend
    // cypherString along with new user to make sure it all matches
    // if it does, attach that to user-permissions
    // and redirect this user to the correct place based on their role and permission level
    let redirectURLTarget;
    if (cypherString) {
      const permissionAssignmentValidation = await validatePermissionAssignment(
        user,
        cypherString
      );

      if (permissionAssignmentValidation.response.status === 200) {
        // refresh token without log out to refresh it
        const newToken = await user.getIdTokenResult(true);

        redirectURLTarget = "/my/loans?status=privelegedsignup";
      }
    }

    await redirectAfterSignInOrSignUp(
      user,
      setUser,
      setUserProfile,
      setViewMode,
      router,
      isNewUser,
      redirectURLTarget,
      setShowLogInDialog
    );

    return;
  } catch (error) {
    console.log(error.code);

    if (error.code === "auth/email-already-in-use") {
      setPasswordErrorMessage("Account with this email already exists");
    }
    return;
  }
}

export default handleSignUp;
